/**
 * Module dependencies,
 */

import { GetStaticProps, NextPage } from 'next';
import { Header } from 'src/components/layout/homepage/header';
import { Locale } from 'i18n-routes';
import { Navbar } from 'src/components/layout/navbar';
import { SSGProps } from 'src/types/app';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouteResolver } from 'src/hooks/use-route-resolver';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

/**
 * `StyledHeader` styled component.
 */

const StyledHeader = styled(Header)`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
`;

/**
 * `404` page.
 */

const NotFound: NextPage = () => {
  const { t } = useTranslation('common');
  const resolveRoute = useRouteResolver();

  return (
    <>
      <Navbar hasNavigation />

      <StyledHeader
        buttonLabel={t('labels.initialPage')}
        buttonUrl={resolveRoute('home')}
        lead={t('error.network.404')}
        title={t('error.network.notAvailable')}
      />
    </>
  );
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<SSGProps> = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as Locale, ['common'])),
      hideFooter: true
    },
    revalidate: 60
  };
};

/**
 * Export `404` component.
 */

export default NotFound;
